import { CssBaseline } from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import renderBloks from '@renderBloks'
import ACC_THEME from '@themes'
import React from 'react'
import SbEditable from 'storyblok-react'

const ModuleReferencesModule = ({ blok }) => {
  const { modules } = blok

  return (
    <SbEditable content={blok}>
      <ThemeProvider theme={ACC_THEME}>
        <CssBaseline />
        {renderBloks(modules)}
      </ThemeProvider>
    </SbEditable>
  )
}

export default ModuleReferencesModule
